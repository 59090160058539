/* You can add global styles to this file, and also import other style files */

@import "primeng/resources/primeng.css"
@import "primeicons/primeicons.css"
@import "node_modules/primeflex/primeflex.css"
@import 'quill/dist/quill.snow.css'

html, body
  margin: 0
  height: 100% !important
  font-family: var(--font-family)
  background: var(--surface-ground)
.material-symbols-outlined
  font-variation-settings: 'FILL' 1, 'wght' 200, 'GRAD' 200, 'opsz' 48

router-outlet.router-flex + *
  display: flex
  flex: 1 1 auto
  flex-direction: column

h2.module-title
  font-weight: 200
  margin: 6px 0
  color: var(--primary-color)

h5.module-subtitle
  margin: 0
  font-weight: 100

.divider-slim
  margin: 0.5rem 0 !important

.card-form, .card-list-item
  box-shadow: 0 2px 3px 2px rgba(0,0,0,.03)
  background: var(--surface-a)

.card-list-item
  .card-item-title
    font-weight: 200
    color: var(--primary-color)
    font-size: 16px
    text-decoration: none
  .card-item-tags
    font-size: 13px
  .card-item-buttons
    gap: 10px

.p-inputnumber,
.p-dropdown,
.p-chips
  width: 100%

.p-chips
  .p-inputtext
    width: 100%

p-multiSelect
  .p-multiselect
    width: 100%

.color-chip
  font-size: 14px
  padding: 2px 5px
  border-radius: 3px

.text-danger
  color: var(--text-danger)
  &.p-menuitem
    .p-menuitem-link
      .p-menuitem-icon, .p-menuitem-text
        color: var(--text-danger)
      &:hover
        .p-menuitem-icon, .p-menuitem-text
          color: var(--text-danger)

.loading-container
  position: relative
  height: 400px

.center-relatively
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.box
  background-color: var(--surface-e)

.box-elevation
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)

.app-vg-overlay-play-container
  cursor: pointer
  &:hover

    .app-vg-overlay-play
      background-color: rgba(0,0,0,0.7)

    .app-vg-overlay-play-inner
      transform: scale(1.1)

.app-vg-overlay-play
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 100px
  height: 100px
  border-radius: 50%
  background-color: rgba(0,0,0,0.5)
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  transition: .1s ease

  &:hover
    background-color: rgba(0,0,0, .7)

    .app-vg-overlay-play-inner
      transform: scale(1.1)

  .app-vg-overlay-play-inner
    width: 0
    height: 0
    border-top: 20px solid transparent
    border-bottom: 20px solid transparent
    border-left: 30px solid white
    margin-left: 10px

@mixin ellipsis($lineas: 1)
  display: -webkit-box !important
  -webkit-line-clamp: $lineas
  -webkit-box-orient: vertical
  overflow: hidden

.ellipsis-1
  @include ellipsis(1)

.ellipsis-2
  @include ellipsis(2)

.ellipsis-3
  @include ellipsis(3)

.ellipsis-4
  @include ellipsis(4)

.custom-ellipsis-span-3
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  line-clamp: 5

.p-input-filled .p-float-label .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext
  padding-top: .5rem

.youtube-player iframe
  width: 100%
  height: 100%

.drag-container
  max-width: 100%
  display: inline-block
  vertical-align: top

.drag-list
  border: solid 1px #ccc
  min-height: 60px
  background: white
  border-radius: 4px
  overflow: hidden
  display: block

.draggable-box
  padding: 20px 10px
  border-bottom: solid 1px #ccc
  color: rgba(0, 0, 0, 0.87)
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  box-sizing: border-box
  cursor: move
  background: white
  font-size: 14px

.cdk-drag-preview
  box-sizing: border-box
  border-radius: 4px
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2)
  position: absolute
  z-index: 100000 !important

.cdk-drag-placeholder
  opacity: 0

.cdk-drag-animating
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1)

.draggable-box:last-child
  border: none

.drag-list.cdk-drop-list-dragging .draggable-box:not(.cdk-drag-placeholder)
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1)

app-custom-toggle-button.w-full
  button
    width: 100%

.p-frozen-column
  z-index: 1000
